<template lang="pug">
  div(
    style="height: 100vh;display: flex; align-items: center;flex-direction: column;justify-content: center;"
  )
    template(v-if="!file && !error")
      Spinner(:withTitle="false")
      h2.blue--text.mt-5 Экспорт...
      span.mt-5(style="max-width:400px;text-align: center;") Не закрывайте страницу,
        |  файл начнет скачиваться автоматически, когда сгенерируется.
        |  Это может занять некоторое время.
    template(v-else-if="file && !error")
      h2.blue--text Экспорт
      span.mt-5(style="max-width:400px;text-align: center;") Файл успешно сгенерировался
      |  и начал скачиваться.
      v-btn.mt-5(
          ref="downloadLink"
          :download="fileName"
          :href='file'
          color="primary"
        ) Скачать повторно
      i {{fileName}}
    template(v-else-if="error")
      h2.red--text Экспорт
      span.mt-5(style="max-width:400px;text-align: center;") Произошла ошибка. Попробуйте позже.
      v-btn.mt-5(
          v-if="exportOptions"
          @click="executeQuery"
          color="primary"
        ) Повторить запрос
</template>

<script>
import Spinner from '@/components/Spinner.vue';

export default {
  components: { Spinner },
  data() {
    return {
      file: null,
      error: false,
      fileName: '',
      exportOptions: null,
    };
  },
  mounted() {
    if (!window.exportOptions) {
      this.error = true;
      return; // this.$router.push('/');
    }
    const exportOptions = JSON.parse(window.exportOptions);
    this.fileName = exportOptions.fileName;
    this.exportOptions = exportOptions;

    this.executeQuery();
  },
  methods: {
    async executeQuery() {
      this.error = false;
      try {
        const data = await this.$store.dispatch(this.exportOptions.action, {
          ...this.exportOptions.query,
          orderType: this.exportOptions.query ? 'DESC' : 'ASC',
        });
        this.file = data;
        this.$nextTick(() => this.$refs.downloadLink.$refs.link.click());
      } catch (error) {
        console.warn(error);
        this.error = true;
      }
    },
  },
};
</script>
